import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
} from "antd";
import { useLoader } from "../Loader/LoadContext";
const { Option } = Select;

interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (data: any) => void;
  onEdit: (data: any) => void;
  dataToHandle: any
}

const AddEditBuyerSupplier: React.FC<DrawerProps> = ({
  visible,
  onClose,
  onAdd,
  onEdit,
  dataToHandle,

}) => {
  const [form] = Form.useForm();
  const [isGSTValid, setIsGSTValid] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState('Add Buyer/Supplier');
  const { setLoading } = useLoader()

  useEffect(() => {
    if (dataToHandle) {
      console.log('edit part');
      setIsEdit(true);
      setTitle('Edit Buyer/Supplier');
      form.setFieldsValue(dataToHandle); // Prefill the form with editData
    } else {
      console.log('add part');
      setIsEdit(false);
      setTitle('Add Buyer/Supplier');
      form.resetFields(); // Reset the form when no editData
    }
  }, [dataToHandle, form]);

  const onFinish = (values: any) => {
    if (isEdit) {
      onEdit({ ...dataToHandle, ...values }); // Preserve existing ID and other data
    } else {
      onAdd(values);
      form.resetFields();
    }
    
    onClose();
  };

  const handleGstChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const gstNumber = e.target.value;
    setIsGSTValid(gstNumber.length === 15);
  };

  return (
    <Drawer
      placement="right"
      title={title}
      onClose={onClose}
      visible={visible}
      width={720}
      extra={
        <Space>
          <Button type="primary" onClick={() => {
            setLoading(true);
            form.submit();
            setLoading(false);
          }}>
            {isEdit ? "Update" : "Submit"}
          </Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <p style={{ color: "grey" }}>Person Contact Details</p>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please enter a name" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please enter an email" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                { required: true, message: "Please enter a phone number" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <p style={{ color: "grey" }}>Company Details</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Radio.Group style={{ width: "100%", marginLeft: "px" }}>
            <Row>
              <Col span={8}>
                <Radio value="Buyer">Buyer</Radio>
              </Col>
              <Col span={8}>
                <Radio value="Supplier">Supplier</Radio>
              </Col>
              <Col span={8}>
                <Radio value="Both">Both</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </div>

        <Row gutter={16} style={{ marginTop: 16 }}>
          <Col span={8}>
            <Form.Item
              name="gstNumber"
              label="GST Number"
              rules={[{ required: true, message: "Please enter a GST number" }]}
            >
              <Input onChange={handleGstChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Button
              type="primary"
              onChange={handleGstChange}
              disabled={!isGSTValid}
              style={{ marginTop: 30 }}
            >
              Fetch Details
            </Button>
          </Col>
          <Col span={8}>
            <Form.Item
              name="gstType"
              label="GST Type"
              rules={[{ required: true, message: "Please select a GST type" }]}
            >
              <Select placeholder="Select GST Type">
                <Option value="regular">Regular</Option>
                <Option value="irregular">Irregular</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            height: 24,
            backgroundColor: 'silver',
            margin: "16px 0",
            padding: "0 8px",
          }}
        >
          <span>
            Verify the GST number to capture all the details automatically
          </span>
        </div>

        {
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="companyName"
                  label="Company Name"
                  rules={[
                    { required: true, message: "Please enter a company name" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="companyEmail"
                  label="Company Email"
                  rules={[
                    { required: true, message: "Please enter a company email" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="addressLine1"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="addressLine2" label="Address Line 2">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="pincode"
                  label="Pincode"
                  rules={[
                    { required: true, message: "Please enter a pincode" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "Please enter a city" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[{ required: true, message: "Please enter a state" }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter a country" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                height: 24,
                backgroundColor: "silver",
                margin: "16px 0",
                padding: "0 8px",
              }}
            >
              <span>
                100% Safe and Compliant with Indian Govt Laws and Regulations
              </span>
            </div>
            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter your country" }]}
            >
              <Radio.Group style={{ width: "100%", marginLeft: "px" }}>
                <Row>
                  <Col span={6}>
                    <Radio value="homeAddress">Home Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="officeAddress">Office Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="deliveryAddress">Delivery Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="billingAddress">Billing Address</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </>
        }
      </Form>
    </Drawer>
  );
};

export default AddEditBuyerSupplier;
