import {
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Layout,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  TableProps,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { addUser, getUsers } from "../../redux/actions/API/users";
import { IDataResponse } from "../../redux/types/API/ApiResponse";

interface IUsermanagementState {
  addUserOpen: boolean;
  // onViewDetails: (item: any) => void;
  onEdit: (item: any) => void;
  // onDelete: (id: number) => void;
  editUserOpen: boolean;
  currentEditItem: DataType | null;
  submittedData: any[];
  usersData: any;
  name: string;
  email: String;
  contact: String;
  team: String;
}
interface IUsermanagementProps {
  userId: number,
  getUsersState: IDataResponse,
  getUsers: Function,
  addUser: Function,
  addUserState: IDataResponse,
  usersData: any,
  companyId: number
}

interface DataType {
  key: string;
  name: string;
  email: string;
  contact: string;
  team: string;
  usersData: any;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Contact",
    dataIndex: "contactNo",
    key: "contactNo",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Team",
    key: "role",
    dataIndex: "role",
  },
  {
    title: "Actions",
    key: "action",
    render: (_, record: any) => (
      <div style={{ display: "flex", gap: 8 }}>
        <Tooltip title="Edit Details">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              console.log("Edited clicked");
            }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Popconfirm
            title="Are you sure to delete this?"
            // onConfirm={handleDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Tooltip>
      </div>
    ),
  },
];

class Usermanagement extends React.Component<
  IUsermanagementProps,
  IUsermanagementState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IUsermanagementProps) {
    super(props);
    this.state = {
      addUserOpen: false,
      onEdit: (value: DataType) => { },
      editUserOpen: true,
      currentEditItem: null,
      name: "",
      email: "",
      contact: "",
      team: "",
      submittedData: [],
      usersData: this.props.usersData
    };
  }

  componentDidMount(): void {
    this.props.getUsers({
      companyId: this.props.companyId
    });
  }

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  onAddUser = () => {
    this.setState({
      addUserOpen: true,
      editUserOpen: false,
      currentEditItem: null,
    });
  };

  onFinish = (value: any) => {
    console.log(value);
  };

  handleSubmit = () => {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        if (this.state.editUserOpen && this.state.currentEditItem) {
          this.handleEditSubmit(values);
        } else {
          this.handleAddSubmit(values);
        }
        this.formRef.current?.resetFields();
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
      });
  };

  handleAddSubmit = (values: any) => {
    this.setState((prevState) => ({
      submittedData: [
        ...prevState.submittedData,
        { key: (prevState.submittedData.length + 1).toString(), ...values },
      ],
      addUserOpen: false,
    }));
  };

  handleEditSubmit = (values: any) => {
    const { currentEditItem } = this.state;
    if (currentEditItem) {
      this.setState((prevState) => ({
        submittedData: prevState.submittedData.map((item) =>
          item.key === currentEditItem.key ? { ...item, ...values } : item
        ),
        editUserOpen: false,
      }));
    }
  };

  handleEdit = (item: DataType) => {
    this.setState(
      {
        editUserOpen: true,
        addUserOpen: false,
        currentEditItem: item,
      },
      () => {
        this.formRef.current?.setFieldsValue(item);
      }
    );
  };

  handleDelete = (key: string) => {
    this.setState((prevState) => ({
      submittedData: prevState.submittedData.filter((item) => item.key !== key),
    }));
  };

  render() {
    const { submittedData, addUserOpen, editUserOpen, currentEditItem } =
      this.state;
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>User Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search users"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.onAddUser}
                style={{ marginLeft: "auto" }}
              >
                Add User
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Table columns={columns} dataSource={this.state.usersData} rowKey="key" />
          </Content>
        </Layout>
        <Drawer
          title={editUserOpen ? "Edit user" : "Add a user"}
          width={720}
          onClose={() => {
            this.setState({
              addUserOpen: false,
              editUserOpen: false,
            });
          }}
          open={addUserOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => {
                  this.setState({
                    addUserOpen: false,
                    editUserOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} type="primary">
                Submit
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    { required: true, message: "Please enter name" },
                  ]}
                >
                  <Input placeholder="Please enter name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[{ required: true, message: "Please enter email" }]}
                >
                  <Input placeholder="Please enter email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="username"
                  label="Username"
                  rules={[
                    { required: true, message: "Please enter user name" },
                  ]}
                >
                  <Input placeholder="Please enter user name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="contact"
                  label="Contact Number"
                  rules={[
                    { required: true, message: "Please enter contact number" },
                  ]}
                >
                  <Input placeholder="Please enter contact number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="team"
                  label="Team"
                  rules={[{ required: true, message: "Please select a team" }]}
                >
                  <Select
                    showSearch
                    placeholder="Team"
                    optionFilterProp="label"
                    onChange={this.onChange}
                    onSearch={this.onSearch}
                    style={{ marginRight: "20px" }}
                    options={[
                      {
                        value: "management",
                        label: "Management",
                      },
                      {
                        value: "legal",
                        label: "Legal",
                      },
                      {
                        value: "finance",
                        label: "Finance",
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  usersData: state.api.getUsers?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getUsersState: state.api.getUsers,
  addUserState: state.api.addUser
});

const mapDispatchToProps = (dispatch: any) => ({
  getUsers: (payload: any) => dispatch(getUsers(payload)),
  addUser: (payload: any) => dispatch(addUser(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Usermanagement);
