import {
  Breadcrumb,
  Button,
  Layout,
  Input,
  Space,
  Drawer,
  Form,
  Row,
  Col,
  Checkbox,
  Tooltip,
  Popconfirm,
  Table,
  TableProps,
  FormInstance,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import React from "react";

interface IStoresmanagementProps {}

interface IStoresmanagementState {
  addEditStoreOpen: boolean;
  addStore: boolean;
  onEditStore: any | null;
  onEdit: (item: any) => void;
  data: any[];
  address1: string;
  address2: string;
  pinCode: Number;
  city: string;
  state: string;
  country: string;
  onChangeTitle: boolean;
  isCheckboxDisabled: boolean;
}

interface DataType {
  key: string;
  storeName: string;
  storeType1: boolean;
  storeType2: boolean;
  storeAddress: string;
  data: any;
}

// const data: DataType[] = [
//   {
//     key: "1",
//     storeName: "ABCtool",
//     storeType1: true,
//     storeType2: false,
//     storeAddress: "jubilee hills",
//     data: null,
//   },
// ];

class StoresManagement extends React.Component<
  IStoresmanagementProps,
  IStoresmanagementState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: IStoresmanagementProps) {
    super(props);
    this.state = {
      addEditStoreOpen: false,
      onEditStore: null,
      addStore: false,
      onEdit: (value: DataType) => {},
      data: [],
      address1: "",
      address2: "",
      pinCode: 0,
      city: "",
      state: "",
      country: "",
      onChangeTitle: false,
      isCheckboxDisabled: true,
    };
  }

  private columns: TableProps<DataType>["columns"] = [
    {
      title: "Store Name",
      dataIndex: "storeName",
      key: "storeName",
    },
    {
      title: "Store Type",
      dataIndex: "storeType",
      key: "storeType",
    },
    {
      title: "Store Address",
      dataIndex: "storeAddress",
      key: "storeAddress",
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="Edit Details">
            <Button
              icon={<EditOutlined />}
              onClick={() => this.handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => this.handleDelete(record.key)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  handleEdit = (item: any) => {

    // Split the address string into components
    const addressParts = item.storeAddress.split(", ");
    const [storeAddress1, storeAddress2, pinCode, city, state, country] = [
      addressParts[0] || "",
      addressParts[1] || "",
      addressParts[2] || "",
      addressParts[3] || "",
      addressParts[4] || "",
      addressParts[5] || "",
    ];

    const storeTypes: Record<string, boolean> = {
      "In Store": false,
      "Reject Store": false,
    };

    item.storeType.forEach((type: string) => {
      storeTypes[type] = true;
    });

    this.setState({
      addEditStoreOpen: true, // Open the drawer
      onEditStore: item, // Set the current item being edited
      onChangeTitle: true, // Set title to "Edit Store"
    });

    // Populate the form fields with the item data
    this.formRef.current?.setFieldsValue({
      storeName: item.storeName || "",
      storeAddress1: storeAddress1 || "",
      storeAddress2: storeAddress2 || "",
      pinCode: pinCode || "",
      city: city || "",
      stateAddress: state || "",
      countryAddress: country || "",
      storeType: [
        storeTypes["In Store"] ? "In Store" : null,
        storeTypes["Reject Store"] ? "Reject Store" : null,
      ].filter(Boolean),
    });
  };

  handleDelete = (key: string) => {
    this.setState((prevState) => ({
      data: prevState.data.filter((item) => item.key !== key),
    }));
  };

  onClose = () => {
    this.formRef.current?.resetFields();
    this.setState({ addEditStoreOpen: false });
  }; // for click on X button

  // Add Store
  handleAddStore = () => {
    this.setState({
      addEditStoreOpen: true, // Open the drawer
      onEditStore: null, // Clear any existing item
      onChangeTitle: false, // Set title to "Add a Store"
    });

    // Clear the form fields for a new store
    this.formRef.current?.resetFields();
  };

  // For Submit
  handleSubmit = () => {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        const { onEditStore, data } = this.state;

        // Combine all address fields into a single string
        const storeAddress = `${values.storeAddress1}, ${values.storeAddress2}, ${values.pinCode}, ${values.city}, ${values.stateAddress}, ${values.countryAddress}`;

        const newData = {
          key: Date.now().toString(), 
          storeName: values.storeName,
          storeType: values.storeType, // Ensure this matches your table's expected format
          storeAddress: storeAddress, // Combined address string
        };

        if (onEditStore) {
          // Edit existing item
          this.setState({
            data: data.map((item) =>
              item.key === onEditStore.key ? { ...item, ...newData } : item
            ),
          });
        } else {
          // Add new item
          this.setState({
            isCheckboxDisabled: false,
            data: [...data, newData],
          });
        }

        // Clear the form and close the drawer
        this.formRef.current?.resetFields();
        this.setState({
          addEditStoreOpen: false,
          onEditStore: null,
          onChangeTitle: false,
        });
      })
      .catch((errorInfo) => {
        console.error("Form validation failed:", errorInfo);
      });
  };

  render() {
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Store Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search Stores"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.handleAddStore}
                style={{ marginLeft: "auto" }}
              >
                Add Store
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              minHeight: 280,
              background: "#f5f5f5",
              borderRadius: "10px",
            }}
          >
            <Table
              columns={this.columns}
              dataSource={this.state.data}
              rowKey="key"
            />
          </Content>
        </Layout>

        <Drawer
          title={this.state.onChangeTitle ? "Edit Store" : "Add a Store"}
          width={720}
          onClose={() => {
            this.setState({
              addEditStoreOpen: false,
              onEditStore: null,
              onChangeTitle: false, // Reset the title for "Add a Store"
            });
          }}
          open={this.state.addEditStoreOpen}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button
                onClick={() => this.setState({ addEditStoreOpen: false })}
              >
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} type="primary">
                {this.state.onChangeTitle ? "Update" : "Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="storeName"
                  label="Store Name"
                  rules={[
                    { required: true, message: "Please enter store name" },
                  ]}
                >
                  <Input placeholder="Please enter store name" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeAddress1"
                  label=" Address Line 1"
                  rules={[
                    { required: true, message: "Please enter Address Line 1" },
                  ]}
                >
                  <Input placeholder="Please enter address line 1" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeAddress2"
                  label="Store Address 2"
                  rules={[
                    { required: true, message: "Please enter address line 2" },
                  ]}
                >
                  <Input placeholder="Please enter address line 2" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="pinCode"
                  label="Pin Code"
                  rules={[{ required: true, message: "Please enter Pin Code" }]}
                >
                  <Input placeholder="Please enter Pin Code" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[{ required: true, message: "Please enter city" }]}
                >
                  <Input placeholder="Please enter city" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="stateAddress"
                  label="State"
                  rules={[{ required: true, message: "Please enter state" }]}
                >
                  <Input placeholder="Please enter state " />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="countryAddress"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter country address" },
                  ]}
                >
                  <Input placeholder="Please enter country address" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="storeType"
                  label="Store Type"
                  rules={[
                    {
                      required: true,
                      message: "Please select at least one store type",
                    },
                  ]}
                >
                  <Checkbox.Group>
                    <Checkbox value="In Store">In Stock Store</Checkbox>
                    <Checkbox
                      disabled={this.state.isCheckboxDisabled}
                      value="Reject Store"
                    >
                      Reject store
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}

export default StoresManagement;
