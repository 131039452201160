import * as type from '../types/API/userTypes'
import { IAction } from './userMetadataReducer'

const initialState = {
    data: null,
    loading: false,
    error: null,
}

export const usersReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.GET_USERS:
            return {
                ...state,
                loading: true,
            }
        case type.GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.responseData
            }
        case type.GET_USERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}

export const addUserReducer = (state = initialState, action: IAction) => {
    switch(action.type) {
        case type.ADD_USER:
            return {
                ...state,
                loading: true,
                error: null
            }
        case type.ADD_USER_SUCCESS:
            return {
                error: null,
                loading: false,
                data: action.payload.responseData
            }
        case type.ADD_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
        default:
            return state;
    }
}
