import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  FormInstance,
  Input,
  Layout,
  List,
  Row,
  Space,
  notification
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { connect } from "react-redux";
import { getTeams, addTeams } from "../../redux/actions/API/team";
import Loader from "../../Home/Loader/Loader";
import { IDataResponse } from "../../redux/types/API/ApiResponse";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface ITeamState {
  addUserOpen: boolean;
  editItem: any | null;
  submittedData: any[];
  team: String;
  teamDesc: String;
  isEditTeam: boolean;
  loading: boolean
}

interface ITeamProps {
  teamsData: any;
  getTeams: Function,
  addTeams: Function,
  userId: number,
  getTeamsState: IDataResponse,
  addTeamsState: IDataResponse,
  companyId: any
}

class Team extends React.Component<
  ITeamProps,
  ITeamState
> {
  private formRef = React.createRef<FormInstance>();
  constructor(props: ITeamProps) {
    super(props);
    this.state = {
      addUserOpen: false,
      editItem: null,
      submittedData: [],
      team: "",
      teamDesc: "",
      isEditTeam: true,
      loading: true
    };
  }

  componentDidMount(): void {
    this.props.getTeams({ companyId: Number(this.props.companyId) });
  }

  componentDidUpdate(prevProps: Readonly<ITeamProps>, prevState: Readonly<ITeamState>, snapshot?: any): void {
    if (prevProps.getTeamsState.loading && !this.props.getTeamsState.loading) {
      if (this.props.getTeamsState?.error?.length > 0) {

      } else {
        this.setState({
          loading: false
        });
      }
    }

    if (prevProps.addTeamsState?.loading && !this.props.addTeamsState?.loading) {
      this.props.getTeams({
        companyId: this.props.userId
      });
      this.openNotificationWithIcon('success');
    }
  }

  openNotificationWithIcon = (type: NotificationType) => {
    notification.open({
      message: 'Teams added Succesfully',
      type: type,
      duration: 3,
    });
  };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  handleSubmit = () => {
    this.setState({
      loading: true
    })
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        console.log(values);
        this.props.addTeams({
          companyId: this.props.userId,
          name: values.teamname,
          description: values.teamdescription,
          ip_address: "127.0.0.1"
        })
        // Clear the form and close the drawer
        this.formRef.current?.resetFields();

        this.setState({
          addUserOpen: false,
        });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
      });
  };

  handleEdit = (item: any) => {
    this.setState({
      addUserOpen: true,
      editItem: item,
      isEditTeam: true,
    });
    this.formRef.current?.setFieldsValue(item);
  };

  // handleDelete = (id: number) => {
  //   this.setState((prevState) => ({
  //     submittedData: prevState.submittedData.filter((item) => item.id !== id),
  //   }));
  // };

  render() {
    // const { submittedData, addUserOpen, isEditTeam } = this.state;
    return (
      <>
        <Loader loading={this.state.loading}></Loader>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Team Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search Teams"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={() => {
                  this.setState({
                    addUserOpen: true
                  })
                }}
                style={{ marginLeft: "auto" }}
              >
                Add Team
              </Button>
            </div>
          </div>

          {/* The content we have to show below after submitting the team in the drawer button */}
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              paddingRight: 20,
              minHeight: 280,
              background: "#FFFFFF",
              borderRadius: "10px",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.props?.teamsData ? this.props.teamsData : []}
              pagination={{
                onChange: (page) => {
                  console.log(page);
                },
                pageSize: 8,
              }}
              renderItem={(item: any) => (
                <>
                  <List.Item
                    key={item.id}
                    actions={[
                      <a
                        onClick={() => this.handleEdit(item)}
                        key="list-loadmore-edit"
                      >
                        Edit
                      </a>,
                      <a
                        onClick={() => { }}
                        key="list-loadmore-more"
                      >
                        Delete
                      </a>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${item.id}`}
                        />
                      }
                      title={item.name}
                      description={item.description}
                    />
                  </List.Item>
                </>
              )}
            />
          </Content>
        </Layout>

        {/* Drawer for teamManagement  */}
        <Drawer
          visible={this.state.addUserOpen}
          // title="Add a team"
          title={"Add a team"}
          width={720}
          onClose={() => this.setState({ addUserOpen: false })}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={() => this.setState({ addUserOpen: false })}>
                Cancel
              </Button>
              <Button onClick={this.handleSubmit} type="primary">
                {"Submit"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="teamname"
                  label="Team Name"
                  rules={[
                    { required: true, message: "Please enter team name" },
                  ]}
                >
                  <Input placeholder="Please enter team name" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="teamdescription"
                  label="Team Description"
                  rules={[
                    {
                      required: true,
                      message: "please enter team description",
                    },
                  ]}
                >
                  <Input.TextArea
                    rows={4}
                    placeholder="please enter team description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  teamsData: state.api.getTeams?.data?.reverse(),
  userId: state.api.login.data.id,
  companyId: state.api.login.data.companyId,
  getTeamsState: state.api.getTeams,
  addTeamsState: state.api.addTeam
});

const mapDispatchToProps = (dispatch: any) => ({
  getTeams: (payload: any) => dispatch(getTeams(payload)),
  addTeams: (payload: any) => dispatch(addTeams(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Team);
