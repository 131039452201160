import React, { useState } from "react";
import {
  Table,
  Tooltip,
  Button,
  Popconfirm,
  Layout,
  Breadcrumb,
  Input,
  Select,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import DrawerComponent from "./Drawer";
import { title } from "process";
import AddEditBuyerSupplier from "./Drawer";

const BuyerSupplier: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any | null>(null);
  const [isEditBtn, setIsEditBtn] = useState(false);
  const [isViewBtn, setIsViewBtn] = useState(false);
  const [dataToHandle, setDataToHandle] = useState<any>(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen); // true, Search button click hone par Drawer open.
    setDataToHandle(null); // drawer open hone par editData null hoga.
  };

  const handleAdd = (newData: any) => {
    setData((prevData) => [...prevData, { ...newData, id: Date.now() }]);
  };

  // submittedData: submittedData.map((item) =>
  //   item.id === editItem.id ? { ...item, ...values } : item

  const handleEdit = (updatedData: any) => {
    setIsOpen(!isOpen);
    setIsEditBtn(true);
    setData((prevData) =>
      prevData.map((item) =>
        item.id === updatedData ? { ...item, ...updatedData } : item
      )
    );
  };

  const handleMenuClick = (item: any, { key }: { key: string }) => {
    if (key === "view") {
      setIsViewBtn(true);
      setIsOpen(true);
      setDataToHandle(item);
    } else if (key === "edit") {
      handleEdit(item);
      // setIsEdit(true)
      // setIsOpen(true);
    } else if (key === "delete") {
      handleDelete(item.id);
    }
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const handleDelete = (id: number) => {
    setData((prevData) => prevData.filter((item) => item.id !== id));
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Company Email",
      dataIndex: "companyEmail",
      key: "companyEmail",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: any, record: any) => (
        <div style={{ display: "flex", gap: 8 }}>
          <Tooltip title="View Details">
            <Button
              icon={<EyeOutlined />}
              onClick={() => handleMenuClick(record, { key: "view" })}
            />
          </Tooltip>

          <Tooltip title="Edit">
            <Button
              icon={<EditOutlined />}
              onClick={() => handleMenuClick(record, { key: "edit" })}
              style={{ marginLeft: 8 }}
            />
          </Tooltip>

          <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Layout
        style={{
          padding: "0 24px 24px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
              flex: 1, // Take up remaining space
            }}
          >
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Buyer/Supplier</Breadcrumb.Item>
          </Breadcrumb>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Select
              showSearch
              placeholder="Search by type"
              optionFilterProp="label"
              onChange={onChange}
              onSearch={onSearch}
              style={{ marginRight: "20px" }}
              options={[
                {
                  value: "buyer",
                  label: "Buyer",
                },
                {
                  value: "supplier",
                  label: "Supplier",
                },
                {
                  value: "both",
                  label: "Both",
                },
              ]}
            />
            <Input
              placeholder="Search Buyer/Supplier"
              style={{ marginRight: "20px" }}
            />
            <Button
              type="primary"
              onClick={toggleDrawer}
              style={{ marginLeft: "auto" }}
            >
              Add Buyer/Supplier
            </Button>
          </div>
        </div>
        <Content
          style={{
            paddingTop: 10,
            margin: 0,
            paddingLeft: 0,
            minHeight: 280,
            background: "#f5f5f5",
            borderRadius: "10px",
          }}
        >
          <Table columns={columns} dataSource={data} rowKey="id" />
        </Content>
      </Layout>
      <AddEditBuyerSupplier
        visible={isOpen}
        onClose={toggleDrawer}
        onAdd={handleAdd}
        onEdit={handleEdit}
        dataToHandle={dataToHandle} 
      />
    </>
  );
};

export default BuyerSupplier;
