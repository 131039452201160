import * as type from '../../types/API/userTypes'

export function getUsers(payload: any) {
    return {
        type: type.GET_USERS,
        payload: payload
    }
}

export function addUser(payload: any) {
    return {
        type: type.ADD_USER,
        payload: payload
    }
}