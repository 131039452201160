import {combineReducers } from 'redux'
import appLocationReducer from './appLocationReducer';
import {getCategoriesReducer } from './categoriesProductsReducer';
import login from './login'
import {teamsReducer, addTeamsReducer} from './teams';
import userMetaDataReducer from './userMetadataReducer';
import { addBlogReducer, deleteBlogReducer, getBlogByIdReducer, getBlogsReducer } from './blogs';
import { imageReducer } from './imageUpload';
import { addUserReducer, usersReducer } from './users';

const UIReducers = combineReducers({
    appLocation: appLocationReducer,
});
const APIReducers = combineReducers({
    login: login,
    addBlog: addBlogReducer,
    //editBlog: editBlogReducer,
    deleteBlog: deleteBlogReducer,
    getBlogs: getBlogsReducer,
    getBlogById: getBlogByIdReducer,
    getCategories: getCategoriesReducer,
    imageUpload : imageReducer,
    addTeam: addTeamsReducer,
    getTeams: teamsReducer,
    addUser: addUserReducer,
    getUsers: usersReducer
});

const RootReducer = combineReducers({
    api: APIReducers,
    ui: UIReducers
});

export default RootReducer;