import {
  Avatar,
  Breadcrumb,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Layout,
  List,
  Radio,
  Row,
  Space,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { FormInstance } from "antd/lib/form";

interface IAddressState {
  addAddress: boolean;
  editItem: any | null;
  submittedData: any[];
  address1: String;
  address2: String;
  pinCode: Number;
  city: String;
  state: String;
  country: String;
  isEditAddress: boolean;
  addressType: "Home" | "Office" | "Delivery" | "Billing";
}

interface IAddressProps {}

// const data = [
//   {
//     title: "Home Address",
//   },
//   {
//     title: "Office Address",
//   },
//   {
//     title: "Delivery Address",
//   },
//   {
//     title: "Billing Address",
//   },
// ];

class Address extends React.Component<{}, IAddressState> {
  private formRef = React.createRef<FormInstance>();

  constructor(props: IAddressProps) {
    super(props);
    this.state = {
      addAddress: false,
      submittedData: [],
      editItem: null,
      addressType: "Home", // Default address
      address1: "",
      address2: "",
      pinCode: 0, // Default pinCode value
      city: "",
      state: "",
      country: "",
      isEditAddress: true,
    };
  }

  onAddAddress = () => {
    this.setState({
      addAddress: true,
      editItem: null,
    });
  };

  onClose = () => {
    this.setState({ addAddress: false });
  };

  // Ye Code sahi hai
  // handleSubmit = (values: any) => {
  //   console.log("Received values from form: ", values);
  //   // let existingData = this.state.submittedData;
  //   // console.log(this.state.submittedData.length);
  //   // let data = [values, {id = this.state.submittedData.length}];
  //   values["id"] = this.state.submittedData.length;
  //   let data = values;
  //   this.setState((prevState) => ({
  //     submittedData: [...prevState.submittedData, data],
  //     addAddress: false, // Close the drawer after submission
  //   }));
  //   this.formRef.current?.resetFields();
  // };

  onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  onSearch = (value: string) => {
    console.log("search:", value);
  };

  handleSubmit = () => {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        const { editItem, submittedData } = this.state;

        if (editItem) {
          // Edit existing item
          this.setState({
            submittedData: submittedData.map((item) =>
              item.id === editItem.id ? { ...item, ...values } : item
            ),
          });
        } else {
          // Add new item
          this.setState((prevState) => ({
            submittedData: [
              ...prevState.submittedData,
              { ...values, id: Date.now() }, // Adding a unique ID for each item
            ],
          }));
        }

        // Clear the form and close the drawer
        this.formRef.current?.resetFields();
        this.setState({ addAddress: false, editItem: null });
      })
      .catch((errorInfo) => {
        console.log("Validate Failed:", errorInfo);
      });
  };

  handleEdit = (item: any) => {
    this.setState({
      addAddress: true,
      editItem: item,
      isEditAddress: false
    });
    this.formRef.current?.setFieldsValue(item);
  };

  handleDelete = (id: number) => {
    // console.log("Delete Clicked");
    // console.log(this.state.submittedData.filter((item) => item.id !== id));
    // console.log("Id is :", id);
    this.setState({
      submittedData: this.state.submittedData.filter((item) => item.id !== id),
    });
  };

  render() {
    const { submittedData, addAddress, isEditAddress } = this.state;
    return (
      <>
        <Layout
          style={{
            padding: "0 24px 24px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
                flex: 1, // Take up remaining space
              }}
            >
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Address Management</Breadcrumb.Item>
            </Breadcrumb>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Input
                placeholder="Search address"
                style={{ marginRight: "20px" }}
              />
              <Button
                type="primary"
                onClick={this.onAddAddress}
                style={{ marginLeft: "auto" }}
              >
                Add Address
              </Button>
            </div>
          </div>
          <Content
            style={{
              paddingTop: 10,
              margin: 0,
              paddingLeft: 0,
              paddingRight: "20px",
              minHeight: 280,
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={submittedData}
              renderItem={(item, index) => (
                <>
                  <List.Item
                    key={index}
                    actions={[
                      <a
                        onClick={() => this.handleEdit(item)}
                        key="list-loadmore-edit"
                      >
                        Edit
                      </a>,
                      <a
                        onClick={() => {
                          // console.log(index);
                          // console.log(this.state.submittedData);
                          this.handleDelete(item.id);
                        }}
                        key="list-loadmore-more"
                      >
                        Delete
                      </a>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`https://api.dicebear.com/7.x/miniavs/svg?seed=${index}`}
                        />
                      }
                      title={
                        <a href="javascript:void(0);">{item.addressType}</a>
                      }
                      description={`${item.addressLine1}, ${item.city}, ${item.state}, ${item.country} - ${item.pincode}`}
                      // "Ant Design, a design language for background applications, is refined by Ant UED Team"
                    />
                  </List.Item>
                </>
              )}
            />
          </Content>
        </Layout>

        <Drawer
          visible={addAddress}
          // title="Add an address"
          title={isEditAddress ? "Edit an address" : "Add an address"}
          width={720}
          onClose={this.onClose}
          styles={{
            body: {
              paddingBottom: 80,
            },
          }}
          extra={
            <Space>
              <Button onClick={this.onClose}>Cancel</Button>
              <Button
                onClick={this.handleSubmit}
                type="primary"
                form="addressForm"
                key="submit"
                htmlType="submit"
              >
                {isEditAddress ? "Submit" : "Update"}
              </Button>
            </Space>
          }
        >
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            ref={this.formRef}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="addressLine1"
                  label="Address Line 1"
                  rules={[
                    { required: true, message: "Please enter an address" },
                  ]}
                >
                  <Input placeholder="Please enter an address" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="addressLine2" label="Address Line 2">
                  <Input placeholder="please enter address description" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="pincode"
                  label="Pincode"
                  rules={[
                    { required: true, message: "Please enter a pincode" },
                  ]}
                >
                  <Input placeholder="please enter pincode" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="city"
                  label="City"
                  rules={[
                    { required: true, message: "Please enter your city" },
                  ]}
                >
                  <Input placeholder="please enter your city" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="state"
                  label="State"
                  rules={[
                    { required: true, message: "Please enter your state" },
                  ]}
                >
                  <Input placeholder="please enter your state" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    { required: true, message: "Please enter your country" },
                  ]}
                >
                  <Input placeholder="please enter your country" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="addressType"
              label="Address Type"
              rules={[{ required: true, message: "Please enter address type" }]}
            >
              <Radio.Group style={{ width: "100%", marginLeft: "px" }}>
                <Row>
                  <Col span={6}>
                    <Radio value="homeAddress">Home Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="officeAddress">Office Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="deliveryAddress">Delivery Address</Radio>
                  </Col>
                  <Col span={6}>
                    <Radio value="billingAddress">Billing Address</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  }
}

export default Address;
