import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { AppConstants } from '../../Appconstants';
import { ADD_TEAM, ADD_TEAM_FAILED, ADD_TEAM_SUCCESS, GET_TEAMS, GET_TEAMS_FAILED, GET_TEAMS_SUCCESS } from '../types/API/teamTypes';
import { GET_CATEGORIES_SUCCESS, GET_CATEGORIES_FAILED, GET_CATEGORIES, LOGIN_USER, LOGIN_USER_FAILED, LOGIN_USER_SUCCESS } from '../types/API/types';
import { ADD_BLOG, ADD_BLOG_FAILED, ADD_BLOG_SUCCESS, DELETE_BLOG, DELETE_BLOG_FAILED, DELETE_BLOG_SUCCESS, EDIT_BLOG, EDIT_BLOG_FAILED, EDIT_BLOG_SUCCESS, GET_BLOGS, GET_BLOG_BY_ID, GET_BLOG_BY_ID_FAILED, GET_BLOG_BY_ID_SUCCESS, GET_BLOGS_FAILED, GET_BLOGS_SUCCESS } from '../types/API/blogTypes';
import { UploadImageAction, UPLOAD_IMAGE, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_FAILED } from '../types/API/imageUploadTypes';
import { uploadImageSuccess, uploadImageFailed } from '../actions/API/imageUpload';
import { jwtDecode } from 'jwt-decode'
import { ADD_USER, ADD_USER_FAILED, ADD_USER_SUCCESS, GET_USERS, GET_USERS_FAILED, GET_USERS_SUCCESS } from '../types/API/userTypes';

function getApiWorker(endpoint) {
    var url = AppConstants.APP_API_URL + endpoint;
    return fetch(url, {
            headers: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
            method: AppConstants.API_METHOD.GET
        }).then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                throw error;
            }
            return response.json();
        });
}

function postApiWorker(endpoint, requestBody) {
    var url = AppConstants.APP_API_URL + endpoint;
    return fetch(url, {
            headers: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
            method: AppConstants.API_METHOD.POST,
            body: JSON.stringify(requestBody)
        }).then(response => {
            if (!response.ok) {
                const error = new Error(response.statusText);
                throw error;
            }
            return response.json();
        });
}

function* login(requestBody) {
    try {
        console.log(requestBody)
        const response = yield call(postApiWorker, "authentication/login", requestBody.payload);
        console.log(jwtDecode(response.token));
        yield put({ type: LOGIN_USER_SUCCESS, payload: { responseData: {
            email: requestBody.payload.email,
            message: response.message,
            token: response.token,
            id: jwtDecode(response.token).userId,
            companyId: jwtDecode(response.token).companyId
        }} });
    }
    catch(error) {
        yield put({ type: LOGIN_USER_FAILED, payload: {error : error} });
    }
}

function* getTeams(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "teams", requestBody.payload);
        yield put({ type: GET_TEAMS_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: GET_TEAMS_FAILED, payload: {error : error} });
    }
}

function* getUsers(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "users", requestBody.payload);
        yield put({ type: GET_USERS_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: GET_USERS_FAILED, payload: {error : error} });
    }
}

function* addUser(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "users/addUser", requestBody.payload);
        yield put({ type: ADD_USER_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: ADD_USER_FAILED, payload: {error : error} });
    }
}

function* getCategories(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(getApiWorker, "get_categories", requestBody.payload);
        yield put({ type: GET_CATEGORIES_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: GET_CATEGORIES_FAILED, payload: {error : error} });
    }
}

function* addBlog(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "blogs/addBlog", requestBody.payload);
        yield put({ type: ADD_BLOG_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: ADD_BLOG_FAILED, payload: {error : error} });
    }
}

function* addTeam(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "teams/addTeam", requestBody.payload);
        yield put({ type: ADD_TEAM_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: ADD_TEAM_FAILED, payload: {error : error} });
    }
}

function* editBlog(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "blogs/editBlog", requestBody.payload);
        yield put({ type: EDIT_BLOG_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: EDIT_BLOG_FAILED, payload: {error : error} });
    }
}

function* deleteBlog(requestBody) {
    console.log(requestBody.payload)
    try {
        const response = yield call(postApiWorker, "blogs/editBlog", requestBody.payload);
        yield put({ type: DELETE_BLOG_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: DELETE_BLOG_FAILED, payload: {error : error} });
    }
}

function* getBlogById(requestBody) {
    console.log(requestBody.payload)
    try {
        const { id } = requestBody.payload
        const response = yield call(getApiWorker, `blogs/${id}`, requestBody.payload.id);
        yield put({ type: GET_BLOG_BY_ID_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: GET_BLOG_BY_ID_FAILED, payload: {error : error} });
    }
}

function* getBlogs(requestBody) {
    try {
        const response = yield call(getApiWorker, "blogs", requestBody.payload);
        yield put({ type: GET_BLOGS_SUCCESS, payload: { responseData: response} });

    }
    catch(error) {
        yield put({ type: GET_BLOGS_FAILED, payload: {error : error} });
    }
}

function* uploadImage(action){
    try {
        const response = yield call(postApiWorker, 'images/uploads', action.payload);
        yield put({ type: UPLOAD_IMAGE_SUCCESS, payload: { url: response.data.url } });
    } catch (error) {
        yield put({ type: UPLOAD_IMAGE_FAILED, payload: { error: error.message } })
    }
} 

function* easeMarginSagas() {
    yield takeEvery(LOGIN_USER, login);
    yield takeEvery(ADD_BLOG, addBlog);
    yield takeEvery(EDIT_BLOG, editBlog);
    yield takeEvery(GET_BLOG_BY_ID, getBlogById);
    yield takeEvery(GET_BLOGS, getBlogs);
    yield takeEvery(DELETE_BLOG, deleteBlog);
    yield takeEvery(GET_CATEGORIES, getCategories);
    yield takeEvery(UPLOAD_IMAGE, uploadImage)
    yield takeEvery(GET_TEAMS, getTeams);
    yield takeEvery(ADD_TEAM, addTeam);
    yield takeEvery(GET_USERS, getUsers);
    yield takeEvery(ADD_USER, addUser);
}

export default easeMarginSagas;